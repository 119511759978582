import * as React from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loginUser } from '../../helpers/authHelper.ts'; 
import alteris_logo from '../../assets/alteris_logo.svg';

export const LoginPage: React.FC = () => {
  
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const { login } = useContext(AuthContext);

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    await loginUser(email, password, login);
  };

  return (
    <main className="flex items-center justify-center text-base bg-sky-950 min-h-screen">
      <ToastContainer />
      <section className="flex flex-col justify-center items-center px-4 py-8 w-full h-full">
        <div className="flex gap-3 items-center px-8 py-10 mb-0 max-w-full bg-white rounded-xl border-2 border-solid border-sky-400 border-opacity-50 w-full max-w-md relative">
          <img loading="lazy" src={alteris_logo} alt="Company Logo" className="absolute top-4 left-4 object-contain w-[150px]" />
          <div className="flex flex-col self-stretch my-auto w-full mt-10">
            <h1 className="mt-5 text-2xl text-black">Welcome | Login</h1>
            <form onSubmit={handleLogin}>
              <div className="self-stretch w-full">
                <label className="sr-only" htmlFor="input-email">Email</label>
                <input
                  type="email"
                  id="input-email"
                  placeholder="Email"
                  className="flex-1 shrink gap-2 self-stretch py-2.5 pl-2 mt-3 w-full tracking-wide bg-white border border-solid border-slate-300 min-h-[32px] min-w-[00px] text-black"
                  onChange={(e) => setEmail(e.target.value)}
                  aria-label="Email"
                />
              </div>
              <div className="self-stretch w-full">
                <label className="sr-only" htmlFor="input-password">Password</label>
                <input
                  type="password"
                  id="input-password"
                  placeholder="Password"
                  className="flex-1 shrink gap-2 self-stretch py-2.5 pl-2 mt-3 w-full tracking-wide bg-white border border-solid border-slate-300 min-h-[32px] min-w-[00px] text-black"
                  onChange={(e) => setPassword(e.target.value)}
                  aria-label="Password"
                  autoComplete="new-password"
                />
              </div>
              <a href="https://alterishc.com/" className="mt-4 mb-4 text-sm text-sky-700"> &nbsp;  Forgot password?</a> 
              <button type="submit" className="gap-2.5 self-stretch px-4 py-4 mt-4 w-full font-bold text-xl text-white bg-blue-500 rounded-lg hover:bg-blue-600">
                Log In
              </button>
              <p className="mt-4 text-sm text-center text-black">
                Don't have an account?{" "}
                <a href="https://alterishc.com/" className="text-blue-500">Contact Us</a>
              </p>
            </form>
          </div>
        </div>
      </section>
    </main>
  );
};

export default LoginPage;