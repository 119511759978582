// src/App.js
import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './pages/Unauth/LoginPage.tsx';
import Dashboard from './pages/Auth/claimsDashboard/dashboard.tsx';
import Labs from './pages/Auth/labsDashboard/LabsDashboard.tsx'
import { AuthContext } from './context/AuthContext';
import { ToastContainer } from 'react-toastify';

function App() {
  const { isAuthenticated, checkAuthStatus } = useContext(AuthContext);

  // On app load, check if the user is authenticated (e.g., check for token in localStorage)
  useEffect(() => {
    checkAuthStatus();  // This function checks localStorage for token and updates auth state
  }, [checkAuthStatus]);

  return (
    <Router>
    <Routes>
    <Route path="/login" element={!isAuthenticated ? <LoginPage /> : <Navigate to="/dashboard" />} />
    <Route path="/dashboard" element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />} />
    <Route path="/labs" element={isAuthenticated ? <Labs /> : <Navigate to="/login" />} />
    <Route path="/" element={<Navigate to={isAuthenticated ? "/dashboard" : "/login"} />} />
    </Routes>
    <ToastContainer />
  </Router>
);
}

export default App;
