import { ClaimSearchParams,BaseClaimEnvelope, ClaimSummary, DateParams } from './types.ts'; // Import your Pydantic model type definition
import { getToken } from '../context/AuthContext'; // Import getToken if not already imported



const API_BASE_URL = process.env.REACT_APP_HOST_ENDPOINT + '/api/v1/auth';

export const fetchClaims = async (
    params: ClaimSearchParams,
    checkAuthStatus: () => boolean,
    logout: () => void,
    navigate: (path: string) => void 
  ) => {
    // Check if the user is authenticated
    if (!checkAuthStatus()) {
        throw new Error('User is not authenticated');
      }
      const token = getToken(); // Retrieve the auth token
      const response = await fetch(`${API_BASE_URL}/claims/retrieve-837`, {
          method: 'POST', // Use POST method
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params), // Send params in the body for POST request
        });
    
      if (response.status === 401) {
        logout(); 
        navigate('/login');
        throw new Error('Unauthorized, redirecting to login');
      }
  
      if (!response.ok) {
        throw new Error('Failed to fetch claims');
      }
    
      return response.json();
    };

export const createClaim = async (
  claimData: Array<BaseClaimEnvelope>, // Use BaseClaimEnvelope type
  checkAuthStatus: () => boolean,
  logout: () => void,
  navigate: (path: string) => void
) => {
  if (!checkAuthStatus()) {
    throw new Error('User is not authenticated');
  }

  if (!claimData || claimData.length === 0) {
    throw new Error('Claim data cannot be empty');
  }
  
  const token = getToken(); // Retrieve the auth token

  const response = await fetch(`${API_BASE_URL}/claims/build-837`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(claimData), // Send claim data in the body
  });

  if (response.status === 401) {
    logout();
    navigate('/login');
    throw new Error('Unauthorized, redirecting to login');
  }

  if (!response.ok) {
    throw new Error('Failed to create claim');
  }

  return response.json();
};

export const fetchClaimsSummary = async (
  dateParams: DateParams,
  checkAuthStatus: () => boolean,
  logout: () => void,
  navigate: (path: string) => void
): Promise<ClaimSummary> => {
  const { month, year } = dateParams;
  if (!checkAuthStatus()) {
    throw new Error('User is not authenticated');
  }

  const token = getToken();
  const response = await fetch(`${API_BASE_URL}/claims/claims-summary?month=${month}&year=${year}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });

  if (response.status === 401) {
    logout();
    navigate('/login');
    throw new Error('Unauthorized, redirecting to login');
  }

  if (!response.ok) {
    throw new Error('Failed to fetch claims summary');
  }

  return response.json();
};
