import React, { useState, useEffect, useContext, useImperativeHandle, forwardRef } from 'react';
import ClaimCard from './claim_components/ClaimCard.tsx';
import { fetchClaims } from '../../../../helpers/apiHelpers.ts'; 
import { ClaimResponse,ClaimSearchParams } from '../types.ts'; 
import { AuthContext } from '../../../../context/AuthContext.js'; 
import { useNavigate } from 'react-router-dom'; 
import {Suggestion} from '../types.ts';

import approved_icon from '../../assets/status_symbols/approved_icon.svg';
import pending_icon from '../../assets/status_symbols/pending_icon.svg';
import failed_icon from '../../assets/status_symbols/failed_icon.svg';

import green_arrow from '../../assets/status_symbols/green_arrow.svg'
import yellow_arrow from '../../assets/status_symbols/yellow_arrow.svg'
import red_arrow from '../../assets/status_symbols/red_arrow.svg'



const statusStyles = {
  Denied: {
    textColor: 'text-red-600',
    borderColor: 'border-red-500',
    icon: failed_icon,
    arrow: red_arrow,
  },
  Pending: {
    textColor: 'text-yellow-600',
    borderColor: 'border-yellow-500',
    icon: pending_icon,
    arrow: yellow_arrow,
  },
  Approved: {
    textColor: 'text-green-600',
    borderColor: 'border-green-500',
    icon: approved_icon,
    arrow: green_arrow,
  },
};

const ClaimsTable: React.FC<{ selectedFilters: Suggestion[] }> = forwardRef((props, ref) => {
  const { checkAuthStatus, logout} = useContext(AuthContext);
  const [claims, setClaims] = useState<ClaimResponse[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const claimsPerPage = 6;
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState(null);

  useEffect(() => {
    const filters = props.selectedFilters.reduce((acc, filter) => {
      acc[filter.type] = filter.value;
      return acc;
    }, {} as Record<string, string>);
  
    loadClaims(filters);
  }, [props.selectedFilters]);

  const loadClaims = async (filters: Record<string, string>) => {
    try {
      const params: ClaimSearchParams = {
        ...filters,
      };
      const data = await fetchClaims(params, checkAuthStatus, logout, navigate);
      setClaims(data);
    } catch (error) {
      console.error('Error fetching claims:', error);
    }
  };

  useImperativeHandle(ref, () => ({
    loadClaims,
  }));

  const handleOpenModal = (claim) => {
    setSelectedClaim(claim);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedClaim(null);
  };

  const totalPages = Math.ceil(claims.length / claimsPerPage);
  const currentClaims = claims.slice((currentPage - 1) * claimsPerPage, currentPage * claimsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPagination = () => {
    const pages: (number | string)[] = []; // Explicitly define the type
    for (let i = 1; i <= totalPages; i++) {
      if (i <= 3 || i > totalPages - 2 || (i >= currentPage - 1 && i <= currentPage + 1)) {
        pages.push(i);
      } else if (pages[pages.length - 1] !== '...') {
        pages.push('...');
      }
    }
  
    return (
      <div className="flex items-center">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-2 py-1 mx-1 border rounded disabled:opacity-50"
        >
          &lt;
        </button>
        {pages.map((page, index) => (
          <button
            key={index}
            onClick={() => typeof page === 'number' && handlePageChange(page)}
            className={`px-2 py-1 mx-1 border rounded ${currentPage === page ? 'bg-blue-500 text-white' : 'bg-white text-black'}`}
          >
            {page}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-2 py-1 mx-1 border rounded disabled:opacity-50"
        >
          &gt;
        </button>
      </div>
    );
  };

  return (
    <section className="mt-10">
    <div className="grid grid-cols-[1fr_1fr_1fr_1.5fr_1fr_0.75fr_0.75fr] gap-5 pr-20 pl-5 w-full text-base tracking-tight text-neutral-400">
      <div>Name</div>
      <div>Last Seen</div>
      <div>Prescriber</div>
      <div>Provider</div>
      <div className="text-center">Charge</div> 
      <div className="text-center">Details</div>
    </div>
    <div
      className="min-h-[calc(6 * 50px)]" // Assuming each claim row is approximately 50px tall
        style={{ minHeight: `${claimsPerPage * 68}px` }} // Adjust the height as needed
    >
      {currentClaims.map((claim, index) => (
        <div key={index} className="grid grid-cols-[1fr_1fr_1fr_1.5fr_1.5fr_1.5fr_auto] gap-5 py-3 pr-20 pl-5 w-full text-base text-black whitespace-nowrap border-t border-b border-solid border-y-zinc-200 max-md:px-5 max-md:max-w-full">
          <div className="my-auto tracking-tight">{claim.patient_name}</div>
          <div className="my-auto tracking-tight">{claim.claim_date}</div>
          <div className="my-auto tracking-tight">{claim.practitioner}</div>
          <div className="my-auto tracking-tight">{claim.provider}</div>
          <div className="my-auto tracking-tight text-center">${claim.charge}</div> 
          <div className="flex justify-center items-center">
            <button
              onClick={() => handleOpenModal(claim)}
              className={`flex items-center justify-start w-full max-w-xs px-4 py-2 border-2 ${statusStyles[claim.status].borderColor}`}
              style={{ borderRadius: '1rem', width: '150px', height: '42px' }} // Set fixed width and height
            >
              <span className="flex items-center mr-4">
                <img src={statusStyles[claim.status].icon} alt={claim.status} className="mr-2" />
                <span className={statusStyles[claim.status].textColor}>{claim.status}</span>
              </span>
              <img 
                src={statusStyles[claim.status].arrow} 
                alt="Right Arrow" 
                className="ml-2" 
                style={{ width: '24px', height: '12px' }} />          
            </button>
          </div>
        </div>
      ))}
    </div>
    <div className="flex justify-center mt-4">
      {renderPagination()}
    </div>
    {selectedClaim && (
      <ClaimCard
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        firstName="First"
        middleInitial="M"
        lastName="Last"
        caseNumber="1032559870546587"
        totalAmountDue="$50.00"
        insurancePays="$6,498.62"
      />
    )}
    </section>
  );
});

export default ClaimsTable;