import React from 'react';

interface CaseHeaderProps {
  firstName: string;
  middleInitial: string;
  lastName: string;
  caseNumber: string;
}

export const CaseHeader: React.FC<CaseHeaderProps> = ({
  firstName,
  middleInitial,
  lastName,
  caseNumber
}) => {
  return (
    <header className="flex flex-wrap gap-5 justify-between w-full max-md:max-w-full">
      <div className="flex gap-10 items-start text-black max-md:max-w-full">
        <h1 className="text-3xl tracking-tighter basis-auto">
          {firstName} {middleInitial}. {lastName}
        </h1>
        <div className="flex flex-col mt-2">
          <span className="self-start text-base tracking-tight">
            Case Number
          </span>
          <span className="text-2xl tracking-tighter">{caseNumber}</span>
        </div>
      </div>
    </header>
  );
};