import { toast } from 'react-toastify';

export const loginUser = async (email, password, login) => {
  try {
    const response = await fetch(process.env.REACT_APP_HOST_ENDPOINT + "/api/v1/unauth/login", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });

    if (response.ok) {
      const data = await response.json();
      const accessToken = data.access_token;
      localStorage.setItem('token', accessToken);
      login(accessToken); 
      console.log('Login successful:', data);
    } else {
      console.error('Login failed');
      toast.error('Login failed. Please check your credentials and try again.');
    }
  } catch (error) {
    console.error('An error occurred during login:', error);
    toast.error('An error occurred during login. Please try again later.');
  }
};
