import React, { useState, useRef, useContext, useEffect } from 'react';
import SideMenu  from '../SideMenu.tsx';
import DashboardHeader from './components/DashboardHeader.tsx';
import ClaimsTable from './components/ClaimsTable.tsx';
import RecentClaimChanges from './components/RecentClaimChanges.tsx';
import JsonInputModal from './components/claim_components/JsonInputModal.tsx'; // Import the new modal
import plus_button from '../assets/plus_button.svg'
import axios from 'axios'
import {Suggestion} from './types.ts'
import { AuthContext } from '../../../context/AuthContext.js';
import FiltersModal from "./components/FiltersModal.tsx"

import magnifying_glass from '../assets/magnifying_glass.svg';
import filter_logo from '../assets/filter_logo.svg';
import refresh_icon from '../assets/refresh_icon.svg'

axios.defaults.baseURL = process.env.REACT_APP_HOST_ENDPOINT

const ClaimsDashboard: React.FC = () => {
  const [isJsonModalOpen, setJsonModalOpen] = useState(false);
  const claimsTableRef = useRef<{ loadClaims: () => void }>(null);
  const [searchInput, setSearchInput] = useState('');
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<Suggestion[]>([]);
  const { getToken } = useContext(AuthContext);
  
  const suggestionsRef = useRef<HTMLUListElement | null>(null);

  const [isFiltersModalOpen, setFiltersModalOpen] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const typeDisplayNameMap: Record<string, string> = {
    patient_name: "Patient",
    practitioner: "Practitioner",
    provider: "Provider"
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target as Node)) {
        setSuggestions([]);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleOpenFiltersModal = () => {
    setFiltersModalOpen(true);
  };

  const handleCloseFiltersModal = () => {
    setFiltersModalOpen(false);
  };

  const handleAddNewClaimClick = () => {
    setJsonModalOpen(true);
  };

  const handleCloseJsonModal = () => {
    setJsonModalOpen(false);
  };


  const handleRefreshClick = () => {
    setIsRefreshing(true);
    if (claimsTableRef.current) {
      const filters = selectedFilters.reduce((acc, filter) => {
        acc[filter.type] = filter.value;
        return acc;
      }, {} as Record<string, string>);

      claimsTableRef.current.loadClaims(filters);
    }
    setTimeout(() => {
      setIsRefreshing(false);
    }, 500); 
  };

  const handleSuggestionClick = (suggestion: Suggestion) => {
    setSelectedFilters([...selectedFilters, suggestion]);
    setSearchInput('');
    setSuggestions([]);
  };

  const fetchSuggestions = async (keyword: string) => {
    const token = getToken();
    try {
      const response = await axios.get('/api/v1/auth/claims/search-claims', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        params: { keyword}
      });
      const filteredSuggestions = response.data.filter((suggestion: Suggestion) => 
        !selectedFilters.some(
          (filter) => filter.value === suggestion.value && filter.type === suggestion.type
        )
      );
  
      setSuggestions(filteredSuggestions);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchInput(value);
    if (value) {
      fetchSuggestions(value);
    } else {
      setSuggestions([]);
    }
  };

  const handleApplyFilters = (filters: Record<string, string | number>) => {
    const newFilters: Suggestion[] = Object.entries(filters)
      .filter(([key, value]) => value !== '')
      .map(([key, value]) => ({ type: key, value: String(value) }));
  
    setSelectedFilters(newFilters);
    setFiltersModalOpen(false);
  };

  return (
    <main className="flex flex-col md:flex-row overflow-hidden gap-5 items-start py-6 pr-4 pl-4 md:pr-20 md:pl-8 bg-slate-100 min-h-screen">
      <div className="flex flex-col md:flex-row w-full">
        <SideMenu className="mb-5 md:mb-0 md:mr-5" />
        <div className="flex flex-col md:flex-row w-full">
          <div className="flex flex-col w-full md:w-3/4 mr-0 md:mr-5">
            <DashboardHeader />
            <section className="flex flex-col px-4 py-6 mt-3.5 w-full bg-white rounded-lg border border-solid border-neutral-300">
              <div className="flex justify-between items-center">
                <div>
                  <h2 className="text-2xl tracking-tighter text-black">Claims Dashboard</h2>
                  <p className="text-base tracking-tight text-neutral-400">View, Edit and Manage all of your Claims</p>
                </div>
                <div className="flex gap-2">
                  <button
                    className="flex items-center border border-gray-300 text-black px-4 py-2 rounded-md hover:bg-gray-100"
                    onClick={handleAddNewClaimClick}
                  >
                    <img src={plus_button} alt="Plus" className="w-5 h-5 mr-2" />
                    New Claim
                  </button>
                  <button
                    className={`flex items-center border border-gray-300 text-black px-4 py-2 rounded-md hover:bg-gray-100 ${isRefreshing ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={handleRefreshClick}
                    disabled={isRefreshing}
                  >
                    <img src={refresh_icon} alt="Refresh" className="w-5 h-5 mr-2" />
                    {isRefreshing ? 'Refreshing...' : 'Refresh'}
                  </button>
                </div>
              </div>
              <form className="flex flex-wrap gap-4 justify-between items-center self-stretch px-5 py-2.5 mt-6 w-1/4 bg-white rounded-md border border-solid border-neutral-300">
              <div className="flex gap-2.5 self-stretch my-auto w-1/2 relative">
              <img loading="lazy" src={magnifying_glass} alt="Search" className="object-contain shrink-0 self-start aspect-[1.04] w-[25px]" />
                  <label htmlFor="searchInput" className="sr-only">Search</label>
                  <input
                    id="searchInput"
                    type="text"
                    placeholder="Search"
                    className="bg-transparent border-none outline-none w-full"
                    value={searchInput}
                    onChange={handleSearchChange}
                    autoComplete="off"
                  />
                  {suggestions.length > 0 && (
                    <ul ref={suggestionsRef} className="absolute top-full left-0 w-full md:w-[300px] bg-white border border-gray-300 rounded-md shadow-lg">
                      {suggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          className="p-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => handleSuggestionClick(suggestion)}
                        >
                          {suggestion.value} ({typeDisplayNameMap[suggestion.type] || suggestion.type})
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <button type="button" aria-label="Open filters" onClick={handleOpenFiltersModal}>
          <img loading="lazy" src={filter_logo} alt="" className="object-contain shrink-0 self-stretch my-auto aspect-square w-[25px]" />
        </button>
              </form>
              <FiltersModal
                isOpen={isFiltersModalOpen}
                onClose={handleCloseFiltersModal}
                onApplyFilters={handleApplyFilters}
              />
              <div className="flex flex-wrap gap-2 mt-4">
                {selectedFilters.map((filter, index) => (
                  <div key={index} className="flex items-center bg-white border border-black px-3 py-1 rounded-full">
                    <span className="mr-2">{filter.value} ({filter.type})</span>
                    <button
                      className="text-red-500 hover:text-red-700"
                      onClick={() => setSelectedFilters(selectedFilters.filter((f) => f !== filter))}
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>
              <ClaimsTable ref={claimsTableRef} selectedFilters={selectedFilters} />
            </section>
          </div>
        </div>
      </div>
      <JsonInputModal isOpen={isJsonModalOpen} onClose={handleCloseJsonModal} />
    </main>
  );
};


export default ClaimsDashboard;