import React from 'react';
import SideMenu from '../SideMenu.tsx';

const Labs: React.FC = () => {
  return (
    <main className="flex flex-col md:flex-row overflow-hidden gap-5 items-start py-6 pr-4 pl-4 md:pr-20 md:pl-8 bg-slate-100 min-h-screen">
      <div className="flex flex-col md:flex-row w-full">
        <SideMenu className="mb-5 md:mb-0 md:mr-5" />
        <div className="flex flex-col md:flex-row w-full">
        <h1 className="text-2xl font-bold">Labs Page</h1>
        <p>Welcome to the Labs page. Here you can manage lab-related tasks.</p>
      </div>
    </div>
    </main>
  );
};

export default Labs;