import React, { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';

import alteris_logo from '../..//assets/alteris_logo.svg';

import settings_logo from './assets/side_menu/settings_logo.svg';
import exit_button from './assets/side_menu/exit_button.svg';
import dashboard_logo from './assets/side_menu/dashboard_logo.svg';
import calendar_logo from './assets/side_menu/calendar_logo.svg';
import claims_logo from './assets/side_menu/claims_logo.svg';
import chart_logo from './assets/side_menu/chart_logo.svg';
import patients_logo from './assets/side_menu/patients_logo.svg';



interface SideMenuProps {
  className?: string;
}

const menuItems = [
  { icon: dashboard_logo, label: "Dashboard", path: "/dashboard" },
  { icon: patients_logo, label: "Labs", path: "/labs" },
  { icon: calendar_logo, label: "Appointments", path: "/appointments" },
  { icon: claims_logo, label: "Referrals", path: "/referrals" },
  { icon: chart_logo, label: "Reports", path: "/reports" },
];

const supportItems = [
  { icon: settings_logo, label: "Settings" },
  { icon: exit_button, label: "Logout" },
];

const SideMenu: React.FC<SideMenuProps> = ({ className }) => {

  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();


  const handleLogout = () => {
    logout();
    navigate('/login');  // Redirect to login after logging out
  };

  return (
    <nav className={`flex flex-col justify-between self-stretch h-full px-3.5 py-8 text-base tracking-tight bg-white rounded-lg border border-solid border-neutral-300 text-black ${className}`}>
      <div className="flex flex-col w-full rounded-none max-w-[199px] flex-grow">
        <div className="flex flex-col px-3.5 pt-5 pb-2.5 text-black bg-white rounded-md border border-solid border-neutral-300 fill-white stroke-neutral-300 max-md:pr-5">
          <img loading="lazy" src={alteris_logo} alt="Logo" className="object-contain self-center max-w-full aspect-[4.72] w-[118px]" />
          <hr className="shrink-0 mt-4 h-px border border-solid bg-neutral-300 border-neutral-300" />
          <div className="self-start mt-2.5 max-md:ml-1.5">Logged In as</div>
          <div className="self-start max-md:ml-1.5">Administrator</div>
        </div>
        <ul>
          {menuItems.map((item, index) => (
            <li key={index} className={`flex gap-2.5 items-center py-1 pr-2.5 pl-4 mt-2 w-full whitespace-nowrap rounded-md ${location.pathname === item.path ? 'bg-white border border-solid border-neutral-300' : ''}`}>
              <button
                onClick={() => navigate(item.path)}
                className="flex items-center gap-2.5 w-full text-left cursor-pointer"
              >
                <img loading="lazy" src={item.icon} alt="" className="object-contain shrink-0 self-stretch my-auto aspect-square w-[25px]" />
                <span className="flex-1 shrink gap-2.5 self-stretch my-auto rotate-[-1.6081230200044232e-16rad] w-[139px] text-black">{item.label}</span>
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex flex-col py-px w-full whitespace-nowrap rounded-md max-w-[199px] mt-4 max-md:mt-10">
        <ul>
          {supportItems.map((item, index) => (
            <li key={index} className="flex gap-2.5 items-center py-1 pr-2.5 pl-4 mt-2 w-full rounded-md">
              <button 
                onClick={item.label === "Logout" ? handleLogout : undefined}
                className="flex items-center gap-2.5 w-full text-left cursor-pointer"
              >
                <img loading="lazy" src={item.icon} alt="" className="object-contain shrink-0 self-stretch my-auto aspect-square w-[25px]" />
                <span className="flex-1 shrink gap-2.5 self-stretch my-auto rotate-[-1.6081230200044232e-16rad] w-[139px] text-black">{item.label}</span>
                </button>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};
export default SideMenu;