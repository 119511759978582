import React, {useState, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../../context/AuthContext';
import { createClaim } from '../../../../../helpers/apiHelpers.ts';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import exit_button from '../../../assets/exit_button.svg';

interface JsonInputModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const JsonInputModal: React.FC<JsonInputModalProps> = ({ isOpen, onClose }) => {
    const [jsonInput, setJsonInput] = useState('');
    const [isLoading, setIsLoading] = useState(false); // Add loading state
    const { checkAuthStatus, logout } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleSubmit = async () => {
      setIsLoading(true); // Set loading to true when submission starts
      try {
        const parsedJson = JSON.parse(jsonInput);
        if (!Array.isArray(parsedJson)) {
          throw new Error('Input must be a list of JSON objects');
        }
  
        await createClaim(parsedJson, checkAuthStatus, logout, navigate);
        toast.success('Claim created successfully!');
        onClose(); // Close the modal after submission
      } catch (error) {
        console.error('Error:', error);
        toast.error(error.message || 'An error occurred. Please try again.');
      } finally {
        setIsLoading(false); // Set loading to false when submission ends
      }
    };
    
      if (!isOpen) return null;
    
      return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="relative bg-white p-8 rounded-lg shadow-lg w-3/4 max-w-2xl">
            <div className="absolute top-2 right-2">
              <img
                onClick={onClose}
                src={exit_button}
                alt="Close"
                className="cursor-pointer w-10 aspect-square"
              />
            </div>
            <h2 className="text-xl font-bold mb-4">Create Claim</h2>
            <div className="mt-5">
              <textarea
                value={jsonInput}
                onChange={(e) => setJsonInput(e.target.value)}
                placeholder="Paste your JSON here"
                className="w-full h-80 p-4 border border-neutral-300 rounded-md"
              />
            </div>
            <div className="flex justify-end mt-4">
              <button
                onClick={handleSubmit}
                disabled={isLoading} // Disable button when loading
                className={`bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                {isLoading ? 'Submitting...' : 'Submit Claim'} {/* Change button text when loading */}
              </button>
            </div>
          </div>
        </div>
      );
    };

export default JsonInputModal;