import React, { useState } from 'react';

interface FiltersModalProps {
  isOpen: boolean;
  onClose: () => void;
  onApplyFilters: (filters: Record<string, string | number>) => void;
}

const FiltersModal: React.FC<FiltersModalProps> = ({ isOpen, onClose, onApplyFilters }) => {
  const [filters, setFilters] = useState({
    status: '',
    date_from: '',
    date_to: '',
    charge_min: '',
    charge_max: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    onApplyFilters(filters);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-3/4 max-w-md">
        <h2 className="text-xl font-bold mb-4">Filter Claims</h2>
        <div className="flex flex-col gap-4">
        <select name="status" value={filters.status} onChange={handleChange} className="border p-2 rounded">
          <option value="">Select Status</option>
          <option value="Pending">Pending</option>
          <option value="Approved">Approved</option>
          <option value="Denied">Denied</option>
        </select>

        <div className="flex gap-4">
          <div className="flex flex-col">
            <span className="text-gray-500">Date From</span>
            <input
              type="date"
              name="date_from"
              value={filters.date_from}
              onChange={handleChange}
              className="border p-2 rounded"
              placeholder="Date From"
            />
          </div>

          <div className="flex flex-col">
            <span className="text-gray-500">Date To</span>
            <input
              type="date"
              name="date_to"
              value={filters.date_to}
              onChange={handleChange}
              className="border p-2 rounded"
              placeholder="Date To"
            />
          </div>
        </div>
        <input
        type="number"
        name="charge_min"
        value={filters.charge_min}
        onChange={handleChange}
        className="border p-2 rounded"
        placeholder="Minimum Charge"
        min="0" 
      />
      <input
        type="number"
        name="charge_max"
        value={filters.charge_max}
        onChange={handleChange}
        className="border p-2 rounded"
        placeholder="Maximum Charge"
        min="0"  // Ensure no negative values
      />
        </div>
        <div className="flex justify-end mt-4">
          <button onClick={handleSubmit} className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
            Apply Filters
          </button>
        </div>
      </div>
    </div>
  );
};

export default FiltersModal;