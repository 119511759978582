import React, { useEffect, useState, useContext } from 'react';
import { fetchClaimsSummary } from '../../../../helpers/apiHelpers.ts';
import { AuthContext } from '../../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import {DateParams} from '../types.ts';

interface DashboardCardProps {
  title: string;
  amount: string;
}

const DashboardCard: React.FC<DashboardCardProps> = ({ title, amount }) => (
  <div className="flex flex-col px-7 py-5 mx-auto w-full bg-white rounded-lg border border-solid border-neutral-300 max-md:px-5 max-md:mt-4">
    <div className="flex gap-5 justify-between text-2xl tracking-tighter">
      <div className="text-black">{title}</div>
      <div className="text-right text-sky-600 underline">View</div>
    </div>
    <div className="self-start mt-1.5 text-3xl font-bold tracking-tighter text-black">{amount}</div>
    <div className="text-sm text-gray-500 mt-1">Monthly</div> {/* Monthly Indicator */}
  </div>
);

const DashboardHeader: React.FC = () => {
  const [claimsSummary, setClaimsSummary] = useState({ pending_denied_total: 0, approved_total: 0 });
  const { checkAuthStatus, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSummary = async () => {
      try {
        const dateParams: DateParams = { month: new Date().getMonth() + 1, year: new Date().getFullYear() };
        const summary = await fetchClaimsSummary(dateParams, checkAuthStatus, logout, navigate);
        setClaimsSummary(summary);
      } catch (error) {
        console.error('Error fetching claims summary:', error);
      }
    };

    fetchSummary();
  }, [checkAuthStatus, logout, navigate]);

  const totalAmount = claimsSummary.pending_denied_total + claimsSummary.approved_total;

  return (
    <header className="w-full max-md:max-w-full">
      <div className="flex gap-5 max-md:flex-col">
        <DashboardCard title="Pending/Denied Claims" amount={`$${claimsSummary.pending_denied_total.toFixed(2)}`} />
        <DashboardCard title="Approved Claims" amount={`$${claimsSummary.approved_total.toFixed(2)}`} />
        <DashboardCard title="Total Claims" amount={`$${totalAmount.toFixed(2)}`} />
      </div>
    </header>
  );
};

export default DashboardHeader;