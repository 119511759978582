import React from 'react';

interface AmountDetailsProps {
  totalAmountDue: string;
  insurancePays: string;
}

export const AmountDetails: React.FC<AmountDetailsProps> = ({
  totalAmountDue,
  insurancePays
}) => {
  return (
    <section className="flex gap-5 justify-between mt-3 max-w-full text-black w-[370px]">
      <div className="flex flex-col">
        <span className="text-base tracking-tight">Total Amount Due</span>
        <span className="self-start text-3xl tracking-tighter">
          {totalAmountDue}
        </span>
      </div>
      <div className="flex flex-col">
        <span className="self-start text-base tracking-tight">
          Insurance Pays
        </span>
        <span className="text-3xl tracking-tighter">{insurancePays}</span>
      </div>
    </section>
  );
};