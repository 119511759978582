import React from 'react';
import { CaseHeader } from './CaseHeader.tsx';
import { AmountDetails } from './AmountDetails.tsx';

import download_button from '../../../assets/download_logo.svg';
import exit_button from '../../../assets/exit_button.svg';


interface ClaimCardProps {
  isOpen: boolean;
  onClose: () => void;
  firstName: string;
  middleInitial: string;
  lastName: string;
  caseNumber: string;
  totalAmountDue: string;
  insurancePays: string;
}

const ClaimCard: React.FC<ClaimCardProps> = ({
  isOpen,
  onClose,
  firstName,
  middleInitial,
  lastName,
  caseNumber,
  totalAmountDue,
  insurancePays
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="relative bg-white p-5 rounded-lg shadow-lg">
        <div className="absolute top-2 right-2 flex gap-2">
        <img
            src={download_button}
            alt="Download"
            className="cursor-pointer w-7 aspect-square"
          />
          <img
            onClick={onClose}
            src={exit_button}
            alt="Close"
            className="cursor-pointer w-10 aspect-square"
          />
        </div>
        <CaseHeader
          firstName={firstName}
          middleInitial={middleInitial}
          lastName={lastName}
          caseNumber={caseNumber}
        />
        <hr className="shrink-0 mt-5 max-w-full h-px border border-solid bg-neutral-300 border-neutral-300 w-[930px]" />
        <AmountDetails
          totalAmountDue={totalAmountDue}
          insurancePays={insurancePays}
        />
      </div>
    </div>
  );
};


export default ClaimCard;